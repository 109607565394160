export default {
	error: "Oops! Looks like there was an error connecting to the server",
	waiting: "Please wait a few seconds while we generate your data...",
	placeholder: "Search for your orders or products",
	placeholder_bill: "Search for order or tracking",
	QUOTATION: "Quotations",
	order: "Orders",
	trackingNumber: "Tracking Numbers",
	bill: "Invoice",
	items: "Number of Items",
	total: "Total",
	date: "Date",
	billDate: "Invoice Date",
	billExpiredAt: "Invoice Due",
	totalCredit: "Total Credit",
	avaialbleCredit: "Available Credit",
	cuentasPorPagar: "Accounts Receivable",
	totalEnOrdenes: "Total in Orders",
	pastDueBalance: "Past Due Balance",
	successEstado: "Account Statement  will be sent to your email:",
	claimExplain: "In case of claims select applicable products",
	continueToClaim: "To place a claim, you must first select an invoice that complies with the company's policies.",
	orderId: "Order Id",
	orderIdBill: "Order Id",
};
