import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Hero, Section, CategoryList, Btn, Heading, Paragraph, Spacer} from "./style";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import InternalLink from "../../components/InternalLink";
import Layout from "./components/Layout";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
    state = {};

    renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            seo,
            title,
            content,
            categories
        } = getLang(lang).categorias;

        return (
            <Layout>

                <Hero>
                    <LazyLoadImage 
                        src="https://www.noritex.com/uplds/2023/10/27/nZWbtoJ6ZXZDCXXQyBanner-conoce-nuestras-marcas-(1650x650).jpg"
                        alt="Noritex"
                    />
                    <div className="inner">
                        <Heading>
                            <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        </Heading>
                    </div>
                </Hero>

                <Section>
                    <Container>
                        <Paragraph className="justify">
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </Paragraph>
                        <Spacer desktop="100" mobile="50"/>
                        <CategoryList>
                            {categories.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="image">
                                        <LazyLoadImage 
                                            src={`${item.thumb}?d=700x700`}
                                            alt="Noritex"
                                        />
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <InternalLink to={`/marca-privada/categorias/${item.slug}`}>
                                                <Btn className="borderless raised">Conocer más</Btn>
                                            </InternalLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </CategoryList>
                    </Container>
                </Section>

            
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultDescription={seo.description}
                    defaultTitle={seo.title}
                    bodyClassName="reset-body back-top-btn-hidden"
                />

                <ConsentManager lang={lang} bypasConsent={true} />
            </Layout>
        );
    }
}

export default withTracker()(MarcaPrivadaHome);
