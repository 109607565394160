import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import Layout from "./components/Layout";
import { Hero, Section, Heading, Paragraph, Masonry, Spacer, StyledLayout } from "./style";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
    state = {};

    renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang, slug } = this.props.match.params;

        const {
            categories
        } = getLang(lang).categorias;

        const category = categories.find(item => item.slug === slug) || {};

        return (
            <Layout>

                <Hero>
                    <LazyLoadImage 
                        src={`${category.hero}`}
                        alt="Noritex"
                    />
                    <div className="inner">
                        <Heading>
                            <h1><span>{category.title || 'Not found'}</span></h1>
                        </Heading>
                    </div>
                </Hero>

                <Section>
                    <Container>
                        <Paragraph className="justify" dangerouslySetInnerHTML={{__html: category.content}} />
                        <Spacer 
                            desktop={100}
                            mobile={50}
                        />
                        <Masonry>
                            {category.gallery?.map((item, index) => (
                                <LazyLoadImage
                                    key={index} 
                                    src={`${item}?d=600`}
                                    alt="Noritex"
                                />
                            ))}
                        </Masonry>
                    </Container>
                </Section>

                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultDescription={category.description}
                    defaultTitle={category.title}
                    bodyClassName="reset-body back-top-btn-hidden"
                />

                <ConsentManager lang={lang} bypasConsent={true} />
            </Layout>
        );
    }
}

export default withTracker()(MarcaPrivadaHome);
