import React from "react";
import renderIf from "../../utils/renderIf";
import Blueberry from "../../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { ProfileMenu, ProfileHeader } from "./style";
import { Section, Container, Title, Heading } from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import EmptySpace from "../../components/EmptySpace";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {
        menus: [],
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    };

    logout = () => {
        Blueberry.logout();
    };

    renderBody() {
        const { lang, slug } = this.props.match.params;
        const { user } = this.props.store;

        return (
            <>
                <Helmet htmlAttributes={{ lang: lang }}>
                    <title>Mi cuenta</title>
                    <body className="is-bg-light " />
                </Helmet>
                <ProfileHeader isCentered>
                    <h1>
                        {user && user.profile
                            ? `${user.profile.firstName} ${user.profile.lastName}`
                            : "Login"}
                    </h1>
                    <p>
                        {user && user.emails && user.emails.length > 0
                            ? `${user.emails[0].address}`
                            : ""}
                    </p>
                </ProfileHeader>
                <Container>
                    <ProfileMenu>
                        <div>
                            <Title size="7" as="h2">
                                {this._t("myAccount")}
                            </Title>
                            <ul>
                                <li>
                                    <InternalLink to="/account/profile">
                                        {this._t("profile")}
                                    </InternalLink>
                                </li>
                                <li>
                                    <InternalLink to="/account/store">
                                        {this._t("store")}
                                    </InternalLink>
                                </li>
                                <li>
                                    <InternalLink to="/account/security">
                                        {this._t("security")}
                                    </InternalLink>
                                </li>
                                <li>
                                    <InternalLink to="/account/reports">
                                        {this._t("reports")}
                                    </InternalLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Title size="7" as="h2">
                                Soporte
                            </Title>
                            <ul>
                                <li>
                                    <InternalLink to="/help/">
                                        {this._t("help")}
                                    </InternalLink>
                                </li>
                                {/* <li>
                                    <InternalLink to="/tutoriales/">
                                        {this._t("tutorial")}
                                    </InternalLink>
                                </li> */}
                                <li>
                                    <InternalLink to="/claim/">
                                        {this._t("claimForm")}
                                    </InternalLink>
                                </li>
                                {renderIf(this.isLoggedIn())(
                                    <li>
                                        <a onClick={this.logout}>Logout</a>
                                    </li>
                                )}
                                <li className="childless">
                                    <span>App Versión</span> <span>2.1.0</span>
                                </li>
                            </ul>
                        </div>
                    </ProfileMenu>
                </Container>
                <EmptySpace />
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
