import styled from "styled-components";
import { Heading } from "../sdk/Layout";

export const Block = styled.div`
  &:not(:last-child) {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
  h2 {
    font-size: 18px;
  }
  > div:not(:last-child) {
    margin-bottom: 30px;
  }
  ${Heading} {
    margin-bottom: 10px !important;
  }
  @media (max-width: 375px) {
    &:not(:last-child) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  ${Heading} {
    padding-bottom: 5px;
  }
  h2 {
    font-size: 15px;
  }
  h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  }
`;

export const Items = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  width: 25%;
  padding-right: 20px;
  a {
    font-size: 14px;
    padding: 5px 0;
    margin: 5px 0;
    display: block;
    text-decoration: none;
    color: ${props => props.theme.primaryDark};
    :hover {
      text-decoration: underline;
      color: ${props => props.theme.black};
    }
  }
  @media (max-width: 980px) {
    width: 33.33333%;
  }
  @media (max-width: 640px) {
    width: 50%;
  }
  @media (max-width: 375px) {
    padding-right: 10px;
    a {
      font-size: 12px;
      padding: 2px 0;
      margin: 2px 0;
    }
  }
`;
