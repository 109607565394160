import React, { useState } from "react";
import styled from "styled-components";
import { Title, Subtitle, Level, Box } from "../../sdk/Layout";
import ModalPortal from "../../components/ModalPortal";
import { Button } from "../../sdk/Button";
import { Modal } from "../../sdk/Elements";
import { Field, Control } from "../../sdk/Form";
import IconLibrary from "./Icon";

const ModalExtended = styled(Modal)`
    z-index: 999;
`;
const ButtonExtended = styled(Button)`
    span {
        color: #fff;
    }
`;

export default function ModalComponent({
    isActive,
    toggleModal,
    email,
    onChange,
    onSubmit,
    isLoading,
}) {
    return (
        <ModalPortal>
            <ModalExtended className={isActive && "is-active"}>
                <Button onClick={toggleModal}></Button>
                <span onClick={toggleModal}></span>
                <Box>
                    <Title as="h3" size="4">
                        Invitación a compartir "Mi Lista 1"
                    </Title>
                    <Subtitle as="h4" size="6">
                        Ingrese el correo electrónico de la persona que desea
                        invitar a su lista
                    </Subtitle>
                    <Field hasAddons>
                        <Control isExpanded>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                onChange={onChange}
                                value={email}
                                placeholder="Correo electrónico"
                            />
                        </Control>
                        <Control>
                            <ButtonExtended
                                loading={isLoading}
                                onClick={onSubmit}
                                primary
                            >
                                <IconLibrary icon="share" />
                                <span>Invitar</span>
                            </ButtonExtended>
                        </Control>
                    </Field>
                </Box>
            </ModalExtended>
        </ModalPortal>
    );
}
