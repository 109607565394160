export default {
    title: "Gracias por registrarse",
    content:
        "En las próximas horas le contactaremos a través de WhatsApp, e-mail o telefónicamente. ¿Desea contactarse con nuestros agentes? Escríbanos a WhatsApp",
    btn1: "Escribir a whatsapp",
    btn2: "Visite noritex.com",
    btn3: "Visitar Showroom",
    ws_text:
        "Hola, quisiera conocer más de la feria de decoración de Concepts Life",
};
