export default {
	error: "Ups! Parece que hubo un error conectandose al servidor",
	waiting: "Espere unos segundos mientras generamos su data...",
	placeholder: "Busca tu órdenes o productos",
	placeholder_bill: "Buscar por factura o trámite",
	QUOTATION: "Cotizaciones",
	order: "Pedidos",
	trackingNumber: "Trámites",
	bill: "Facturas",
	items: "Cantidad de Items",
	total: "Total",
	date: "Fecha",
	billDate: "Fecha de Factura",
	billExpiredAt: "Vencimiento Factura",
	totalCredit: "Credito Total",
	avaialbleCredit: "Credito Disponible",
	cuentasPorPagar: "Cuentas por Cobrar",
	totalEnOrdenes: "Total en Ordenes",
	pastDueBalance: "Saldo Vencido",
	successEstado: "Estado de cuenta solicitado, se le enviara a su correo electronico:",
	continueToClaim: "Para colocar un reclamo, debe seleccionar primero una factura que cumpla con las políticas de la empresa.",
	orderId: "Order Id",
	orderIdBill: "Factura",
};
