import styled from "styled-components";

import { Buttons, Button } from "../sdk/Button";
import { Tabs, Modal } from "../sdk/Elements";
import {
    Section,
    Container,
    Title,
    Subtitle,
    Box,
    Columns,
    Column,
    Level,
} from "../sdk/Layout";

export const TopSection = styled(Level)`
    margin-bottom: 10px;
`;

export const TabsExteneded = styled(Tabs)`
    ul {
        margin: 0;
        padding: 0;
    }
`;

export const Header = styled.div`
    background-color: ${props => props.theme.white};
    padding: 20px 10px 0 10px;
    border-bottom: solid 1px ${props => props.theme.borderColor};
    ${Title} {
        margin-bottom: 0px;
        @media (max-width: 680px) {
            font-size: 18px;
        }
    }
    ${Tabs} {
        margin-bottom: -1px;
        @media (max-width: 680px) {
            font-size: 12px;
        }
    }
    ${Subtitle} {
        color: ${props => props.theme.gray};
        font-weight: bold;
        text-transform: uppercase;
        span {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            + span:before {
                content: "/";
                color: ${props => props.theme.borderColor};
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
                margin: 0px 10px;
            }
            @media (max-width: 680px) {
                font-size: 11px;
                + span:before {
                    margin: 0px 5px;
                }
            }
        }
    }
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
    }
`;

export const OrderInfo = styled.div`
    padding: 7px 10px;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    span {
        text-transform: uppercase;
        font-weight: bold;
        color: ${props => props.theme.gray};
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        line-height: initial;
        + span {
            margin-left: 20px;
        }
    }
    @media (max-width: 680px) {
        column-count: 2;
        span {
            display: block;
            font-size: 10px !important;
            + span {
                margin-left: 0;
                margin-top: 2px;
            }
        }
    }
`;

export const Orders = styled.div`
    ${Columns} {
        margin: 0;
    }
`;

export const OrderOptions = styled(Buttons)`
    height: 100%;
    align-items: center;
    justify-content: center;
    @media (min-width: 769px) {
        padding-right: 20px;
    }
    a {
        margin-right: 5px;
    }
    ${Button} {
        margin-bottom: 0 !important;
        &:not(:last-child) {
        }
    }
`;

export const OrderItems = styled(Column)`
    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const OrderItem = styled.div`
    figure,
    h1 {
        cursor: pointer;
    }
    ${Columns} {
        margin: 0;
    }
    & + & {
        border-top: dashed 2px ${props => props.theme.borderColor};
    }
`;

export const ItemDetails = styled(OrderInfo)`
    padding: 0;
    border-bottom: 0;
    span {
        font-size: 11px;
        color: ${props => props.theme.primayDark};
    }
`;

export const ItemOptions = styled.div`
    padding-top: 10px;
    font-size: 12px;
    a {
        color: ${props => props.theme.gray};
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        + a {
            margin-left: 10px;
        }
        :hover {
            color: ${props => props.theme.primaryDark};
        }
    }
    @media (max-width: 680px) {
        font-size: 10px;
    }
`;

export const Ordenes = styled.div`
    background-color: ${props => props.theme.grayLight};
    :not(.is-active) {
        display: none;
    }
`;
export const DetalleOrden = styled(Ordenes)``;

export const Order = styled(Box)`
    font-size: 16px;
    padding: 0;
    & + & {
        margin-top: 10px;
    }
    .item-tag {
        background-color: rgba(0, 0, 0, 0.05);
        margin-left: 10px;
        margin-top: -4px;
        display: inline-block;
        padding: 2px 10px;
        font-size: 9px;
    }

    ${Column} {
        .item-img {
            width: 120px;
            position: relative;
        }
        figure {
            display: inline-block;
            vertical-align: middle;
            & + figure {
                margin-left: 10px;
            }
            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
        @media (max-width: 980px) {
            figure img {
                width: 60px;
                height: 60px;
            }
            .item-img {
                width: 70px;
            }
        }
        table {
            width: 100%;
            td {
                vertical-align: middle;
            }
        }
    }
    ${Title} {
        margin-bottom: 0;
        @media (max-width: 680px) {
            font-size: 14px;
        }
    }
`;

export default {};
