import React from "react";
import Helmet from "react-helmet";
import ProductSectionTemplate from "../templates/ProductsSectionTemplate";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry";
import Lists from "./components/Lists";
import SEOFieldsHelmet, { websiteName } from "../utils/SEOFieldsHelmet";

class WishlistsComponent extends ProductSectionTemplate {
    constructor(props) {
        super(props);

        this.state = {
            key: 0,

            isHeaderOpen: true,
            ...this.productDefaultState,
        };
    }

    deleteWishlist = (wishlistId) => {
        return Blueberry.removeWishlist({ wishlistId });
    };

    addWishlistToCart = (products) => {
        if (analytics) analytics.track("Wishtlist Products Added All To Cart");
        const productsToAdd = products.map((index) => ({
            productId: index,
            quantity: null,
        }));
        return Blueberry.productAddToCartMany({ productsToAdd });
    };

    wishlistGenerateExcel = (wishlistId, isPDF) => {
        const windowReference = window.open("", "_blank");
        if (analytics) {
            analytics.track("Wishlist Generated Excel");
        }
        Blueberry.generateProforma({ wishlistId }).then((resp) => {
            console.log(resp);
            windowReference.location = `${resp}${isPDF ? "&type=pdf" : ""}`;
            // window.open(`${resp}${sort ? "&sort=" + sort : ""}`, "_blank");
        });
    };

    emptyWishlist = (wishlistId) => {
        return Blueberry.upsertWishlist({
            wishlist: { _id: wishlistId, products: [] },
        });
    };

    inviteWishlist = (wishlistId, email) => {
        if (analytics) {
            analytics.track("Wishlist Shared");
        }
        return Blueberry.inviteWishlist({
            wishlistId,
            email,
        });
    };

    renderBody() {
        const { wishlists, user } = this.props.store;
        const { lang } = this.props.match.params;
        return (
            <>
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultTitle={`Wishlist - ${websiteName}`}
                    defaultDescription={`Wishlist - ${websiteName}`}
                />
                <Lists
                    user={user}
                    wishlists={wishlists}
                    deleteWishlist={this.deleteWishlist}
                    emptyWishlist={this.emptyWishlist}
                    addWishlistToCart={this.addWishlistToCart}
                    wishlistGenerateExcel={this.wishlistGenerateExcel}
                    inviteWishlist={this.inviteWishlist}
                />
            </>
        );
    }
}

export default withTracker()(WishlistsComponent);
