import React from "react";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Layout } from "./style";
import Icons from "./components/Icons";
// import { Head } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";

function getLang(lang) {
	switch (lang) {
		case "es":
			return lang_es;
		case "en":
			return lang_en;
		case "pt":
			return lang_pt;
	}
}

class ThankYou extends ProductsSectionTemplate {
	state = { modal: false };

	renderHeader = () => null;
	renderFooter = () => null;

	renderBody() {
		const { lang, treatment } = this.props.match.params;

		const { title, content, btn1, btn2, ws_text } = getLang(lang);

		const phones = {
			1: "50761697242",
			2: "50761697242",
			3: "50761697242",
		};

		const phone = phones[treatment ? parseInt(treatment) : 2];
		const link = `https://api.whatsapp.com/send?phone=${phone}&text=${ws_text}`;

		return (
			<Layout>
				<SEOFieldsHelmet
					seo={null}
					// lang={lang}
					defaultTitle={title}
					defaultDescription={content}
					bodyClassName="reset-body back-top-btn-hidden"
				/>
				<div className="container">
					<div className="wrap wrap--800">
						<div>
							<img
								src="https://www.noritex.com/uplds/2021/7/31/bHMJz5o829XjhncNNlogo-conceptslife.png"
								alt="Logo Concepts"
								className="logo"
							/>
						</div>
						<div className="heading">
							<h2>{title}</h2>
							<h3>{content}</h3>
						</div>
						<div className="buttons">
							<a
								href={link}
								target="_blank"
								className="button primary">
								{btn1}
							</a>
							<a
								href={`https://www.noritex.com/${lang}/`}
								target="_blank"
								className="button noritex">
								{btn2}
							</a>
						</div>

						<div className="social">
							<a
								target="_blank"
								href="https://www.facebook.com/conceptslife/">
								<Icons icon="fb" className="icon" />
							</a>
							<a
								target="_blank"
								href="https://www.instagram.com/concepts_life/?hl=es-la">
								<Icons icon="ig" className="icon" />
							</a>
							<a
								target="_blank"
								href="https://www.youtube.com/channel/UCsZQVj4ps-c7sU1a6Hlo5Zw">
								<Icons icon="yt" className="icon" />
							</a>
						</div>
					</div>
				</div>
				<ConsentManager lang={lang} bypasConsent={true} />
			</Layout>
		);
	}
}

export default withTracker()(ThankYou);
