import React from "react";
import { Title, Heading } from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import { Block, Item, Items } from "../style";
import { checkTags } from "../../components/Header";

const Section = ({ submenu, keyTitle }) => {
    if (submenu.children == null || submenu.children.length === 0) return null;
    return (
        <div>
            <Title as="h3" size="6">
                {submenu.title}
            </Title>
            <Items>
                {submenu.children.map((index, i) => (
                    <Item key={`${index._id}-${i}-${keyTitle}`}>
                        <InternalLink {...index}>{index.title}</InternalLink>
                    </Item>
                ))}
            </Items>
        </div>
    );
};

function BlockSection({ menu, user, jsonSettings }) {
    if (menu.children == null || menu.children.length === 0) return null;
    const tags = user ? user.tags : [];

    if (!checkTags(menu.title.toLowerCase(), tags, jsonSettings, user))
        return null;
    return (
        <Block>
            <Heading>
                <h2>{menu.title}</h2>
            </Heading>
            {menu.children.map((index) => (
                <Section
                    key={`${menu.title}-${index.title}`}
                    keyTitle={`${menu.title}-${index.title}`}
                    submenu={index}
                />
            ))}
        </Block>
    );
}

export default React.memo(BlockSection);
