/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import {
	Section, Container, Title, Box, BlankState, Spacer,
} from "../sdk/Layout";
import { Message, Preloader } from "../sdk/Elements";
import { Button } from "../sdk/Button";
import Icons2 from "../components/Icons2";
import {
	Header,
	Searcher,
	AccountInfo,
	AccountPicker,
	TabsExtended,
	TableOptions,
	Table,
	Skeleton,
	LoadingMessage,
	ButtonsExtended,
	ErrorWrapper,
	TableHeader,
	TrackingCell,
	ContainerSearcher,
} from "./styles2";
import Blueberry from "../../blueberry";
import { numberWithCommas, getDate } from "../utils";
import { useTranslation } from "../../lang";
import InternalLink from "../components/InternalLink";
import renderIf from "../utils/renderIf";

// eslint-disable-next-line camelcase
import lang_es from "./lang/lang_es";
// eslint-disable-next-line camelcase
import lang_en from "./lang/lang_en";
// eslint-disable-next-line camelcase
import lang_pt from "./lang/lang_pt";
import useNotification from "../hooks/useNotification";

function getLang(lang) {
	switch (lang) {
		case "es":
			// eslint-disable-next-line camelcase
			return lang_es;
		case "en":
			// eslint-disable-next-line camelcase
			return lang_en;
		case "pt":
			// eslint-disable-next-line camelcase
			return lang_pt;
		default:
			// eslint-disable-next-line camelcase
			return lang_en;
	}
}

function Order({
	order, seeAll, quotation, view, companyId,
	fromUrl, seeMore, seeLess, queryText, claimsPolicyDays,
}) {
	// eslint-disable-next-line
	let orderId, token, count, createdAt, total, trackingNumbers;
	if (quotation) {
		token = order._id;
		orderId = order.systemOrderNumber
			? order.systemOrderNumber
			: order._id
				.slice(order._id.length - 5, order._id.length)
				.toUpperCase();
		count = order.items ? order.items.length : 0;
		total = order.total;
		createdAt = getDate(order.createdAt);
	} else {
		orderId = view === "order"
			? order.orderNumber
			: view === "trackingNumber"
				? order.trackingNumber
				: order.billNumber;
		token = orderId;
		createdAt = getDate(new Date(order.createdDate));
		total = order.totalAmount;
		trackingNumbers = order.trackingNumbers;
	}

	if (queryText && view === "bill"
	&& !trackingNumbers.includes(queryText)
	&& orderId.toString() !== queryText
	) return null;

	const expiredDate = new Date(order.createdDate);
	expiredDate.setDate(expiredDate.getDate() + claimsPolicyDays);

	return (
		<tr>
			<td>{orderId}</td>
			{count ? <td>{count}</td> : null}
			<td>{total ? numberWithCommas(total) : 0}</td>
			<td>{createdAt}</td>
			{
				view === "bill" ? (
					<td>{ getDate(expiredDate) }</td>
				) : null
			}
			{trackingNumbers ? (
				<TrackingNumbersTd trackingNumbers={trackingNumbers} seeMore={seeMore} seeLess={seeLess} />
			) : null}
			<td>
				<InternalLink
					to={`/orders/${token}?view=${view}&companyId=${companyId}&fromUrl=${fromUrl}`}
				>
					<Button small>
						{seeAll} (+{count})
					</Button>
				</InternalLink>
			</td>
		</tr>
	);
}

function TrackingNumbersTd({ trackingNumbers, seeMore, seeLess }) {
	const [showMore, setShowMore] = useState(false);

	const handleClick = () => {
		setShowMore(!showMore);
	};

	const allTrackings = trackingNumbers.join(", ");
	const firstTrackings = `${trackingNumbers.slice(0, 3).join(", ")}...`;
	const showTrackings = (showMore || trackingNumbers.length <= 3) ? allTrackings : firstTrackings;

	return (
		<TrackingCell>
			<article>
				{showTrackings === "0" ? "" : showTrackings}
			</article>
			{trackingNumbers.length > 3 ? (
				<Button small onClick={handleClick}>
					{showMore ? seeLess : seeMore}
				</Button>
			) : null}
		</TrackingCell>
	);
}

function AccountButton({
	handleEstadoDeCuenta, isLoadingStatement, user, children,
}) {
	if (user.roles.findIndex((rol) => rol === "read-accounts") >= 0) {
		return (
			<Button
				onClick={handleEstadoDeCuenta}
				loading={
					isLoadingStatement ? "true" : undefined
				}
			>
				{children}
			</Button>
		);
	}

	return null;
}

function OrdersView({
	lang,
	user,
	accountData,
	isLoading,
	hasError,
	currentCompany,
	reports,
	orders,
	ordersFromErp,
	isLoadingOrders,
	view,
	queryText,
	setQueryText,
	page,
	limit,
	setCurrentView,
	setPage,
	fromUrl,
	jsonSettings,
}) {
	const [open, setOpen] = useState(false);

	const [isLoadingStatement, setIsLoadingStatement] = useState(false);

	const [_t] = useTranslation();
	const [notify, ModalNotify] = useNotification({});

	const langJson = getLang(lang);
	const handleClick = () => {
		setOpen(!open);
	};

	const handleEstadoDeCuenta = () => {
		setIsLoadingStatement(true);

		if (typeof window !== "undefined" && analytics)
			analytics.track("Statement Requested");

		const email = user && user.emails ? user.emails[0].address : "";

		Blueberry.requestStatement({
			email: email,
			companyId: currentCompany.companyId,
		})
			.then(() => {
				notify({
					info: true,
					paragraph: `${langJson.successEstado} ${email}`,
				});
				setIsLoadingStatement(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoadingStatement(false);
			});
	};

	const isNextPageStatic = () => {
		if (view === "QUOTATION" && orders && orders.length >= limit && orders.length !== 0) {
			return false;
		}
		if (view !== "QUOTATION" && ordersFromErp && ordersFromErp.length >= limit && ordersFromErp.length !== 0) {
			return false;
		}

		return true;
	};

	return (
		<Section>
			<Container>
				<ModalNotify />
				{isLoading ? (
					<LoadingMessage>
						<Box className="wrapper">
							<Button
								loading={isLoading}
								as="span"
								className="loading"
							/>
							{isLoading ? <div>{langJson.waiting}</div> : null}
						</Box>
					</LoadingMessage>
				) : null}
				<Header>
					<Title size="4">{_t("myOrders")}</Title>
					<div>
						{isLoading ? (
							<Skeleton height="40">
								<Preloader />
							</Skeleton>
						) : (
							<AccountButton
								handleEstadoDeCuenta={handleEstadoDeCuenta}
								isLoadingStatement={isLoadingStatement}
								user={user}
							>
								{_t("requestStatementOfAccount")}
							</AccountButton>
						)}
					</div>
				</Header>

				<AccountInfo>
					{isLoading ? (
						<Skeleton height="72">
							<Preloader></Preloader>
							<Preloader></Preloader>
							<Preloader></Preloader>
						</Skeleton>
					) : !hasError ? (
						<>
							<AccountPicker onClick={handleClick}>
								<div className="wrapper">
									<div className="profile">
										<img
											src={
												currentCompany &&
                                                currentCompany.store
													? currentCompany.store
														.imageLogo
													: null
											}
											alt=""
										/>
									</div>
									<div className="title">
										{currentCompany && currentCompany.store
											? currentCompany.store.storeName
											: ""}
									</div>
									<span className="arrow">
										<Icons2 icon="chevron-down" />
									</span>
								</div>
								{open ? (
									<div className="account-list">
										<Box>
											<ul>
												{reports.map((index) => (
													<li key={index.companyId}>
														<a
															onClick={() =>
																setCurrentCompany(
																	index
																)
															}
															className={
																index.companyId ===
                                                                currentCompany.companyId
																	? "is-checked"
																	: ""
															}
														>
															{index.store
																? index.store
																	.storeName
																: "No Name"}
														</a>
													</li>
												))}
											</ul>
										</Box>
									</div>
								) : null}
							</AccountPicker>

							<div className="info">
								<span>{langJson.totalCredit}</span>
								<div>
									{numberWithCommas(accountData.clientCredit)}
								</div>
							</div>
							<div className="info">
								<span>{langJson.avaialbleCredit}</span>
								<div>
									{numberWithCommas(
										accountData.cupoCreditoDisponible
									)}
								</div>
							</div>
							<div className="info">
								<span>{langJson.cuentasPorPagar}</span>
								<div>
									{numberWithCommas(accountData.openAmount)}
								</div>
							</div>
							<div className="info">
								<span>{langJson.totalEnOrdenes}</span>
								<div>
									{numberWithCommas(accountData.openOrders)}
								</div>
							</div>
							<div className="info">
								<span>{langJson.pastDueBalance}</span>
								<div>
									{numberWithCommas(
										accountData.pastDueBalance
									)}
								</div>
							</div>
						</>
					) : (
						<ErrorWrapper>
							<LoadingMessage>
								<Box className="wrapper">
									<Message error>{langJson.error}</Message>
								</Box>
							</LoadingMessage>
						</ErrorWrapper>
					)}
				</AccountInfo>

				<TabsExtended marginBottom={10}>
					{isLoading ? (
						<Skeleton height="37">
							<Preloader></Preloader>
						</Skeleton>
					) : (
						<ul className="is-center">
							<li
								className={
									view === "QUOTATION" ? "is-active" : ""
								}
							>
								<a onClick={() => setCurrentView("QUOTATION")}>
									{langJson.QUOTATION}
								</a>
							</li>
							<li
								className={view === "order" ? "is-active" : ""}
								onClick={() => setCurrentView("order")}
							>
								<a>{langJson.order}</a>
							</li>
							<li
								className={
									view === "trackingNumber" ? "is-active" : ""
								}
								onClick={() => setCurrentView("trackingNumber")}
							>
								<a>{langJson.trackingNumber}</a>
							</li>
							<li
								className={view === "bill" ? "is-active" : ""}
								onClick={() => setCurrentView("bill")}
							>
								<a>{langJson.bill}</a>
							</li>
						</ul>
					)}
				</TabsExtended>

				{
					(fromUrl === "claims" && view === "bill" && !isLoading) ?
						<>
							<Message info>
								{langJson.continueToClaim}
							</Message>
							<Spacer />
						</>
						: null
				}

				{isLoading || isLoadingOrders ? (
					<>
						<Skeleton height="100">
							<Preloader></Preloader>
							<Preloader></Preloader>
						</Skeleton>

						<Skeleton multiline height="48">
							<Preloader></Preloader>
							<Preloader></Preloader>
							<Preloader></Preloader>
						</Skeleton>
					</>
				) : orders.length > 0 ||
                  (ordersFromErp && ordersFromErp.length > 0) ? (
						<div>
							<TableHeader>
								<div>
									<Title as="h2" size="5">
										{langJson[view]}
									</Title>
								</div>
								<ContainerSearcher>
									{renderIf(view === "QUOTATION" || view === "bill")(
										<Searcher>
											<input
												type="text"
												value={queryText}
												onChange={(e) =>
													setQueryText(e.target.value)
												}
												placeholder={view === "bill" ? langJson.placeholder_bill : langJson.placeholder}
											/>
											<span className="icon">
												<Icons2 icon="search" />
											</span>
										</Searcher>
									)}
								</ContainerSearcher>
							</TableHeader>
							<TableOptions>
								{/* <div>
                                    <TabsFilter className="is-toggle">
                                        <ul>
                                            <li className="is-active">
                                                <a href="">Todas</a>
                                            </li>
                                            <li>
                                                <a href="">Pagadas</a>
                                            </li>
                                            <li>
                                                <a href="">Pendientes</a>
                                            </li>
                                        </ul>
                                    </TabsFilter>
                                </div>
                                <div>
                                    <Searcher small>
                                        <input
                                            type="text"
                                            placeholder="Buscar cotización..."
                                        />
                                        <span className="icon">
                                            <Icons2 icon="search" />
                                        </span>
                                    </Searcher>
                                </div> */}
							</TableOptions>
							<Table>
								<div className="h-scroll">
									<table>
										<thead>
											<tr>
												<th>
													{view === "bill" ? langJson.orderIdBill : langJson.orderId}
												</th>
												{view === "QUOTATION" ? (
													<th>{langJson.items}</th>
												) : null}
												<th>{langJson.total}</th>
												<th>
													{view === "bill" ? langJson.billDate : langJson.date}
												</th>
												{view === "bill" ? (
													<>
														<th>{langJson.billExpiredAt}</th>
														<th>{langJson.trackingNumber}</th>
													</>
												) : null}
												<th></th>
											</tr>
										</thead>
										<tbody>
											{view === "QUOTATION"
												? orders.map((order) => (
													<Order
														order={order}
														quotation
														view={view}
														key={order._id}
														seeAll={_t("all")}
													/>
												))
												: null}
											{view !== "QUOTATION"
												? ordersFromErp.map((order, index) => (
													<Order
														order={order}
														key={`${order._id}#${index}`}
														companyId={
															currentCompany.companyId
														}
														view={view}
														seeAll={_t("all")}
														seeMore={_t("seeMore")}
														seeLess={_t("seeLess")}
														fromUrl={fromUrl}
														queryText={queryText}
														claimsPolicyDays={jsonSettings?.claimsPolicyDays ?? 90}
													/>
												))
												: null}
										</tbody>
									</table>
								</div>
							</Table>
							<ButtonsExtended isCentered>
								<Button
									onClick={() => setPage(page - 1)}
									static={page <= 1}
								>
									{_t("back")}
								</Button>

								<Button
									primary
									static={isNextPageStatic()}
									onClick={() => setPage(page + 1)}
								>
									{_t("next")}
								</Button>
							</ButtonsExtended>
						</div>
					) : (
						<BlankState>
							<div>
								<h3>
                                Aquí verá sus {langJson[view].toLowerCase()}
								</h3>
							</div>
						</BlankState>
					)}
			</Container>
		</Section>
	);
}
export default OrdersView;
