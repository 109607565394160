import React, { useState } from "react";
import styled from "styled-components";
import {
    Container,
    Section,
    Title,
    Subtitle,
    Level,
    LevelLeft,
    LevelRight,
    LevelItem,
    BlankState,
} from "../../sdk/Layout";
import { Button, Buttons, Icon } from "../../sdk/Button";
import { Dropdown } from "../../sdk/Elements";
import InternalLink from "../../components/InternalLink";
import IconLibrary from "./Icon";
import IconLibrary2 from "../../components/Icons2";
import useConfirm from "../../hooks/useConfirm";
import useNotification from "../../hooks/useNotification";
import Modal from "./Modal";
import { useTranslation } from "../../../lang";
import Blueberry from "../../../blueberry";
import useClickOutside from "../../hooks/useClickOutside";

export const SubtitleExtended = styled(InternalLink)`
    color: ${(props) => props.theme.primaryDark};
    font-weight: 400;
    line-height: 1.25;

    font-size: 1.6rem;
    &:not(:last-child) {
        margin-bottom: 2.4rem;
    }

    svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        color: ${(props) => props.theme.gray};
        margin-top: -4px;
        margin-right: 5px;
    }
`;

const Header = styled.div`
    padding-bottom: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    border-bottom: solid 1px ${(props) => props.theme.borderColor};
    ${Title} {
        margin-bottom: 0;
        @media (max-width: 680px) {
            font-size: 18px;
        }
    }
    ${Button} {
        margin-bottom: 0;
    }

    .divisor {
        height: 40px;
        width: 1px;
        background-color: #ecf0f4;
        margin: 0 15px;
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5%;
    margin-left: -0.5%;
`;

const List = styled.div`
    position: relative;
    width: 49%;
    margin: 0.5%;
    margin-bottom: 30px;
    > a {
        border: solid 1px transparent;
        background-color: ${(props) => props.theme.grayLight};
        padding: 20px;
        border-radius: 3px;
        display: flex;
        margin-bottom: 10px;
        transition: all 200ms linear;
        :hover {
            border-color: #e3e3e3;
        }
    }
    figure {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
        + figure {
            padding-left: 20px;
        }
    }
    ${SubtitleExtended} {
        margin-bottom: 0;
    }

    @media (max-width: 1024px) {
        > a {
            flex-wrap: wrap;
        }
        figure {
            width: 48%;
            margin: 1%;
            padding-top: 50%;
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        width: 99%;
    }
`;

const ListEmpty = styled.div`
    height: 300px;
    width: 100%;
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -15px;
        width: 40px;
        color: #dbdcdf;
    }
`;

const Action = styled(Icon)`
    opacity: 0.6;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;

const Menu = styled(Dropdown)`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            cursor: pointer;
        }
    }
    > div {
        > div {
            border: solid 1px #e3e3e3;
        }
    }
`;
const Bubble = styled.div`
    width: 40px;
    height: 40px;
    text-transform: capitalize;
    font-size: 12px;
    position: absolute;
    top: 10px;
    color: white;
    opacity: 0.8;
    right: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: inset 0 0 0 2px white;
    background-color: #4a4a4a;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
`;

const getInitial = (userName) => {
    if (userName == null || userName.indexOf(" ") === -1) return "";
    const split = userName.split(" ");
    return split[0].substring(0, 1) + " " + split[1].substring(0, 1);
};

const ListComponent = ({
    wishlist,
    deleteWishlist,
    emptyWishlist,
    addWishlistToCart,
    toggleModal,
    wishlistGenerateExcel,
    _t,
}) => {
    const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);
    const menuRef = React.useRef();
    useClickOutside(menuRef, () => setIsOptionsVisible(false));

    const toggleMenu = () => {
        setIsOptionsVisible((prevState) => !prevState);
    };

    const handleDeleteWishlist = () => {
        setIsOptionsVisible(false);
        deleteWishlist(wishlist._id);
    };
    const handleEmptyWishlist = () => {
        emptyWishlist(wishlist._id);

        setIsOptionsVisible(false);
    };
    const handleAddWishlistToCart = () => {
        addWishlistToCart(wishlist.products);
        setIsOptionsVisible(false);
    };
    const handleProforma = () => {
        wishlistGenerateExcel(wishlist._id);
        setIsOptionsVisible(false);
    };
    const handleGeneratePDF = () => {
        wishlistGenerateExcel(wishlist._id, true);
        setIsOptionsVisible(false);
    };
    const handleSharing = () => {
        toggleModal(wishlist._id);
        setIsOptionsVisible(false);
    };

    const images = [];

    for (
        let index = 0;
        index < Math.min(wishlist.topProducts.length, 4);
        index++
    ) {
        const element = wishlist.topProducts[index];
        const thumb =
            element && element.imagesUrl && element.imagesUrl.length > 0
                ? element.imagesUrl[0].thumb
                : "";
        const img =
            element && element.imagesUrl && element.imagesUrl.length > 0
                ? element.imagesUrl[0].url
                : "";
        images.push(
            <figure key={`image-${wishlist._id}-${index}`}>
                <img
                    src={thumb}
                    srcSet={` 
                    ${thumb} 224w,
                    ${img}?d=300x300 300w,
                    ${img}?d=400x400 400w
                    `}
                    alt={element.slug}
                />
            </figure>
        );
    }

    return (
        <List>
            <InternalLink to={`/wishlist/${wishlist._id}`}>
                {/* solo de 1 a 4 items */}
                {images}
                {images && images.length <= 0 ? (
                    <ListEmpty>
                        <IconLibrary2 icon="image" />
                    </ListEmpty>
                ) : null}
                {wishlist.userName ? (
                    <Bubble>{getInitial(wishlist.userName)}</Bubble>
                ) : null}
            </InternalLink>
            <Level isMobile>
                <LevelLeft>
                    <SubtitleExtended to={`/wishlist/${wishlist._id}`} size="6">
                        {wishlist.title}
                    </SubtitleExtended>
                </LevelLeft>
                <LevelRight>
                    <Menu
                        ref={menuRef}
                        isActive={isOptionsVisible}
                        isRight
                        isUp
                    >
                        <Action onClick={toggleMenu} as="a">
                            <IconLibrary icon="elipsis" />
                        </Action>
                        <div>
                            <div>
                                <ul>
                                    <li>
                                        <a onClick={handleProforma}>
                                            <Icon>
                                                <IconLibrary2 icon="excel-blank" />
                                            </Icon>
                                            <span>{_t("generarProforma")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={handleGeneratePDF}>
                                            <Icon>
                                                <IconLibrary2 icon="pdf-blank" />
                                            </Icon>
                                            <span>{_t("generatePdf")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={handleAddWishlistToCart}>
                                            <Icon>
                                                <IconLibrary icon="cart-arrow" />
                                            </Icon>
                                            <span>{_t("addAll")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={handleSharing}>
                                            <Icon>
                                                <IconLibrary icon="share" />
                                            </Icon>
                                            <span> {_t("share")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={handleEmptyWishlist}>
                                            <Icon>
                                                <IconLibrary2 icon="eraser" />
                                            </Icon>
                                            <span>{_t("empty")}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={handleDeleteWishlist}>
                                            <Icon>
                                                <IconLibrary icon="trash" />
                                            </Icon>
                                            <span>{_t("delete")}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Menu>
                </LevelRight>
            </Level>
        </List>
    );
};

function Listas({
    wishlists,
    deleteWishlist,
    emptyWishlist,
    addWishlistToCart,
    wishlistGenerateExcel,
    inviteWishlist,
    user,
}) {
    const [isModalActive, setModalActive] = useState(false);
    const [isSharing, setIsSharing] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);
    const [email, setEmail] = React.useState("");
    const [_t] = useTranslation();
    const [handleNotifyDelete, NotifyDeleteWishlist] = useNotification({
        timeout: 2000,
    });
    const [handleConfirm, ConfirmModal] = useConfirm({
        title: _t("confirmDeleteList"),
        paragraph: "",
        btnConfirm: _t("delete"),
        btnCancel: _t("cancel"),
    });

    const [handleConfirmEmpty, ConfirmModalEmpty] = useConfirm({
        title: _t("confirmEmptyList"),
        paragraph: "",
        btnConfirm: _t("empty"),
        btnCancel: _t("cancel"),
    });

    const toggleModal = (id) => {
        setCurrentId(id);
        setModalActive((prev) => !prev);
    };

    const handleOnChange = (e) => {
        setEmail(e.target.value);
    };

    const handleShare = () => {
        setIsSharing(true);
        inviteWishlist(currentId, email)
            .then((resp) => {
                setModalActive(false);
                setIsSharing(false);
                setEmail("");
                handleNotifyDelete({
                    paragraph: _t("invitationSend"),
                    info: true,
                });
            })
            .catch(() => {
                setIsSharing(false);
                handleNotifyDelete({
                    paragraph: _t("error"),
                    error: true,
                });
            });
    };

    const handelAddAllToCart = (products) => {
        addWishlistToCart(products)
            .then(() => {
                handleNotifyDelete({
                    paragraph: _t("addedAllToCart"),
                    info: true,
                });
            })
            .catch(() => {
                handleNotifyDelete({
                    paragraph: _t("noPermisionsLIst"),
                    error: true,
                });
            });
    };

    const handleEmptyWishlist = (wishlistId) => {
        handleConfirmEmpty({
            callback: function (confirmed) {
                if (confirmed) {
                    emptyWishlist(wishlistId)
                        .then(() => {
                            handleNotifyDelete({
                                paragraph: _t("wishlistEmptied"),
                                info: true,
                            });
                        })
                        .catch(() => {
                            handleNotifyDelete({
                                paragraph: _t("noPermisionsLIst"),
                                error: true,
                            });
                        });
                }
            },
        });
    };

    const handleDeleteWishlist = (wishlistId) => {
        handleConfirm({
            callback: function (confirmed) {
                if (confirmed) {
                    deleteWishlist(wishlistId)
                        .then(() => {
                            handleNotifyDelete({
                                paragraph: _t("wishlistDeleted"),
                                info: true,
                            });
                        })
                        .catch(() => {
                            handleNotifyDelete({
                                paragraph: _t("noPermisionsLIst"),
                                error: true,
                            });
                        });
                }
            },
        });
    };

    React.useEffect(() => {
        Blueberry.getWishlists().catch(() => {});
    }, [user]);

    return (
        <Section>
            <ConfirmModal />
            <ConfirmModalEmpty />
            <NotifyDeleteWishlist />
            <Container>
                <Header>
                    <Level isMobile>
                        <LevelLeft>
                            <LevelItem>
                                <Title size="4" as="h3">
                                    {_t("myList")}
                                </Title>
                            </LevelItem>
                        </LevelLeft>
                        <LevelRight>
                            <LevelItem>
                                {/* <Buttons>
                                    <Button primary>
                                        <IconLibrary />
                                        <span>Crear lista</span>
                                    </Button>
                                </Buttons> */}
                            </LevelItem>
                        </LevelRight>
                    </Level>
                </Header>
                <Subtitle as="h3" size="6">
                    {_t("listHelp")}
                </Subtitle>

                {wishlists && wishlists.length <= 0 ? (
                    <BlankState>
                        <div>
                            <IconLibrary2 icon="stream" />
                            <h3>{_t("noListYet")}</h3>
                            <p>{_t("noListYetHelp")}</p>
                        </div>
                    </BlankState>
                ) : null}
                <ListContainer>
                    {wishlists.map((index, i) => (
                        <ListComponent
                            key={index._id + i}
                            deleteWishlist={handleDeleteWishlist}
                            emptyWishlist={handleEmptyWishlist}
                            wishlist={index}
                            _t={_t}
                            toggleModal={toggleModal}
                            addWishlistToCart={handelAddAllToCart}
                            wishlistGenerateExcel={wishlistGenerateExcel}
                        />
                    ))}
                </ListContainer>
            </Container>
            <Modal
                isActive={isModalActive}
                toggleModal={toggleModal}
                email={email}
                onChange={handleOnChange}
                onSubmit={handleShare}
                isLoading={isSharing}
            />
        </Section>
    );
}

export default Listas;
