import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Notify } from "../sdk/Elements";
import ModalPortal from "../components/ModalPortal";

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const NotifyExtended = styled(Notify)`
    z-index: 999;
    /* animation: 0.5s ${slideInLeft} ease-out; */
`;

export default function useNotification({ timeout = 2000 }) {
    const [isModalVisible, setIsModalVisitble] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [info, setInfo] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [paragraph, setParagraph] = React.useState("Some notification...");
    // const [callback, setCallback] = React.useState(null);
    const toggleModal = () => {
        setIsModalVisitble((prevState) => !prevState);
    };

    function call({ alert, info, error, paragraph }) {
        // setCallback(callback);
        setAlert(alert);
        setInfo(info);
        setError(error);
        setIsModalVisitble(true);
        setParagraph(paragraph);
        setTimeout(() => {
            setIsModalVisitble(false);
        }, timeout);
    }

    return [
        call,
        () =>
            isModalVisible ? (
                <ModalPortal>
                    <NotifyExtended
                        alert={alert}
                        error={error}
                        info={info}
                        left
                    >
                        <span>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="bell"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"
                                />
                            </svg>
                        </span>

                        {paragraph}
                    </NotifyExtended>
                </ModalPortal>
            ) : null,
    ];
}
