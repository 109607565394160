export default {
	error: "Oops! Looks like there was an error connecting to the server",
	waiting: "Please wait a few seconds while we generate your data...",
	placeholder: "Search for your orders or products",
	placeholder_bill: "Search for order or tracking",
	QUOTATION: "Quotations",
	order: "Orders",
	trackingNumber: "Tracking Numbers",
	bill: "Bills",
	items: "Number of Items",
	total: "Total",
	date: "Date",
	billDate: "Data da Fatura",
	billExpiredAt: "Data de Validade",
	totalCredit: "Total Credit",
	avaialbleCredit: "Available Credit",
	cuentasPorPagar: "Accounts Receivable",
	totalEnOrdenes: "Total in Orders",
	pastDueBalance: "Past Due Balance",
	successEstado: "O extrato da conta será enviado para o seu e-mail:",
	claimExplain: "Em caso de reclamações, seleccionar os produtos aplicáveis",
	continueToClaim: "Para fazer uma reclamação, você deve primeiro selecionar uma fatura que esteja de acordo com as políticas da empresa.",
	orderId: "Order Id",
	orderIdBill: "Order Id",
};
