export default {
    title: "Obrigado por se registrar",
    content:
        "Nas próximas horas entraremos em contato via WhatsApp, e-mail ou telefone. Deseja entrar em contato com nossos agentes? Escreva-nos no WhatsApp",
    btn1: "Escreva para Whatsapp",
    btn2: "Visite noritex.com",
    btn3: "Visite Showroom",
    ws_text:
        "Olá, gostaria de saber mais sobre a feira de decoração do lar Concepts Life",
};
