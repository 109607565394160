import React, { useState } from "react";
import styled from "styled-components";
import { Title, Subtitle, Level, Box } from "../sdk/Layout";
import ModalPortal from "../components/ModalPortal";
import { Button, Buttons } from "../sdk/Button";
import { Modal } from "../sdk/Elements";
import { Field, Control } from "../sdk/Form";

const ModalExtended = styled(Modal)`
    z-index: 999;
    > button {
        --safe-area-inset-top: env(safe-area-inset-top, 20px);
        top: calc(var(--safe-area-inset-top) + 20px);
    }
`;

let callback = null;

export default function useConfirm({
    title,
    paragraph,
    btnConfirm,
    btnCancel,
}) {
    const [isModalVisible, setIsModalVisitble] = React.useState(false);
    // const [callback, setCallback] = React.useState(null);
    const toggleModal = () => {
        setIsModalVisitble((prevState) => !prevState);
    };

    function call(props) {
        // setCallback(callback);
        callback = props.callback;

        setIsModalVisitble(true);
    }

    function handleCancel() {
        if (callback != null) {
            callback(false);
            setIsModalVisitble(false);
            return;
        }
        throw new Error("Missing callback");
    }

    function handleConfirm() {
        if (callback != null) {
            callback(true);
            setIsModalVisitble(false);
            return;
        }
        throw new Error("Missing callback");
    }

    return [
        call,
        () => (
            <ModalPortal>
                <ModalExtended className={isModalVisible && "is-active"}>
                    <Button onClick={toggleModal}></Button>
                    <span onClick={toggleModal} />
                    <Box>
                        <Title as="h3" size="4">
                            {title}
                        </Title>
                        {paragraph ? (
                            <Subtitle as="h4" size="6">
                                {paragraph}
                            </Subtitle>
                        ) : null}
                        <Field>
                            <Buttons>
                                <Button onClick={handleCancel}>
                                    {btnCancel}
                                </Button>
                                <Button onClick={handleConfirm} primary>
                                    {btnConfirm}
                                </Button>
                            </Buttons>
                        </Field>
                    </Box>
                </ModalExtended>
            </ModalPortal>
        ),
    ];
}
