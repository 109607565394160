import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Hero, Section, FeaturedImage, InfoBlocks, Heading, Paragraph, Spacer} from "./style";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import Layout from "./components/Layout";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
    state = {};

    renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            seo,
            title,
            content,
            banner,
            heading,
            paragraph1,
            paragraph2
        } = getLang(lang).nosotros;

        return (
            <Layout>

                <Hero>
                    <LazyLoadImage 
                        src="https://www.noritex.com/uplds/2023/8/21/AvGun8oyYdKtJnZxZntx-hero.jpg?d=1100x1100"
                        alt="Noritex"
                    />
                    <div className="inner">
                        <Heading>
                            <h1><span>{title}</span></h1>
                        </Heading>
                    </div>
                </Hero>

                <Section>
                    <Container>
                        <Paragraph className="justify">
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        </Paragraph>
                    </Container>
                </Section>

                <FeaturedImage overlay>
                    <Container>
                        <div className="inner">
                        <LazyLoadImage 
                            src="https://www.noritex.com/uplds/2023/10/27/zXtgMkc8tLTCn9FBHnosotros-Banner2.jpg?d=1100x1100"
                            alt="Noritex"
                        />
                            <h3>{banner}</h3>
                        </div>
                    </Container>
                </FeaturedImage>

                <Section>
                    <Container>
                        <Heading>
                            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        </Heading>
                        <Spacer desktop="100" mobile="50"/>

                        <InfoBlocks>
                            <div>
                                <LazyLoadImage 
                                    src="https://www.noritex.com/uplds/2023/10/27/RFfJdLkJAw2NiMkh4nosotros-img01.jpg?d=700x700"
                                    alt="Noritex"
                                />
                                <Paragraph className="justify">
                                    <p>{paragraph1}</p>
                                </Paragraph>
                            </div>
                            <div>
                                <LazyLoadImage 
                                    src="https://www.noritex.com/uplds/2023/10/27/wkq5249B2dcQ8t3Lynosotros-img02.jpg?d=700x700"
                                    alt="Noritex"
                                />
                                <Paragraph className="justify">
                                    <p>{paragraph2}</p>
                                </Paragraph>
                            </div>
                        </InfoBlocks>
                    </Container>
                </Section>
            
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultTitle={seo.title}
                    defaultDescription={seo.description}
                    bodyClassName="reset-body back-top-btn-hidden"
                />

                <ConsentManager lang={lang} bypasConsent={true} />
            </Layout>
        );
    }
}

export default withTracker()(MarcaPrivadaHome);
