import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { Hero, Section, Paragraph, Heading } from "./style";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import Layout from "./components/Layout";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
    state = {};

    renderHeader = () => null;
    // renderFooter = () => null;

    renderBody() {
        const { lang } = this.props.match.params;

        const {
            seo,
            title,
            countries
        } = getLang(lang).presencia;

        return (
            <Layout>

                <Hero>
                    <LazyLoadImage 
                        src="https://www.noritex.com/uplds/2023/10/24/Pi4sLgqHaBR7j39WKBanner-Prsencia-comercial.jpg"
                        alt="Noritex"
                    />
                    <div className="inner">
                        <Heading>
                            <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        </Heading>
                    </div>
                </Hero>

                <Section>
                    <Container>
                        <Paragraph className="center">
                            <h2 className="title">Artículos del Hogar, Navidad y Escolar</h2>
                            <p>Tienda por Departamento, tienda especializada de decoración, ferretería, mueblerías, distribuidores, Supermercado e hipermercados, tiendas especializadas.</p>
                            <ul className="countries">
                                {countries.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Paragraph>
                    </Container>
                </Section>

            
                <SEOFieldsHelmet
                    seo={null}
                    lang={lang}
                    defaultDescription={seo.description}
                    defaultTitle={seo.title}
                    bodyClassName="reset-body back-top-btn-hidden"
                />

                <ConsentManager lang={lang} bypasConsent={true} />
            </Layout>
        );
    }
}

export default withTracker()(MarcaPrivadaHome);
