import styled from "styled-components";
import { Container, Section, Title } from "../../sdk/Layout";

export const ProfileHeader = styled.div`
    text-align: center;
    padding-top: 20px;
    h1 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    p {
        font-size: 14px;
        color: gray;
    }
`;

export const ProfileMenu = styled.div`
    margin: 10px 0;
    padding: 5px;
    > div {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        & ${Title} {
            margin-bottom: 10px;
            text-transform: uppercase;
        }
    }
    ul {
        display: block;
        background-color: #fff;
        border-radius: 6px;
        padding: 0px 15px;
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            color: #464646;
            a,
            span {
                padding: 15px 0;
            }
            a {
                color: #464646;
                cursor: pointer;
                width: 100%;
                display: block;
            }
            display: block;
            text-decoration: none;
            padding: 0;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(.childless):after {
                content: "";
                width: 7px;
                height: 7px;
                border-right: 1px solid #000;
                border-top: 1px solid #000;
                /*position: absolute;
                right: 10px;
                top: 50%;
                margin-top: -5px;*/
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                opacity: 0.4;
            }
            &:not(:last-child) {
                border-bottom: solid 1px #f2f2f2;
            }
            &.childless {
                color: #9c9c9c;
            }
        }
    }
`;
