/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import Blueberry from "../../../blueberry/index";
import { Button, Buttons, Icon } from "../../sdk/Button";
import {
	Checkbox, Control, Field, Radio, FieldBody,
} from "../../sdk/Form";
import {
	Modal, Preloader, Dropdown, Message,
} from "../../sdk/Elements";
import useNotification from "../../hooks/useNotification";
import InternalLink from "../../components/InternalLink";
import FullScreen from "../../components/Gallery2/FullScreen";
import { getDate, numberWithCommas } from "../../utils";
import { useTranslation } from "../../../lang";
import Icons from "../../components/Icons2";

import {
	Section,
	Container,
	Title,
	Subtitle,
	Box,
	Columns,
	Column,
	Level,
	LevelLeft,
	LevelRight,
	LevelItem,
} from "../../sdk/Layout";

import {
	TabsExteneded,
	Header,
	OrderInfo,
	Orders,
	Order,
	OrderItem,
	ItemDetails,
	ItemOptions,
	Ordenes,
	DetalleOrden,
	OrderItems,
	OrderOptions,
	TopSection,
} from "../styles";
import useQueryString from "../../hooks/useQueryString";
import renderIf from "../../utils/renderIf";

const CheckBoxExtended = styled(Checkbox)`
    span {
        width: 29px;
        height: 29px;
        position: relative;
        &:before {
        content: '';
            width: 6px;
            height:12px;
            border-bottom: solid 2px white;
            border-right: solid 2px white;
            display: block;
            position: absolute;
            left: 9px;
            top: 4px;
            transform: rotate(45deg);
        }
    }
`;

const ModalExtended = styled(Modal)`
    z-index: 9999;
`;
const ButtonExtended = styled(Button)`
    margin-left: 10px;
`;

const ButtonsExtended = styled(Buttons)`
    margin: 20px 0;
`;

const DropdownExtended = styled(Dropdown)`
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

const ClaimExplain = styled.span`
    display: ${(props) => props.view === "bill" ? "inline" : "none"};
    margin-left: 8px;
    color: #112c55;
    font-weight: 700;
    @media (max-width: 700px) {
        font-weight: 400;
        font-size: 11px;
        width: 175px;
    }
`;

function OrderProductPreloader() {
	return (
		<>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
			<OrderItem>
				<Columns>
					<Column>
						<table>
							<tbody>
								<tr>
									<td className="item-img">
										<figure>
											<Preloader square></Preloader>
										</figure>
									</td>
									<td>
										<Preloader></Preloader>
										<Preloader half></Preloader>
									</td>
								</tr>
							</tbody>
						</table>
					</Column>
				</Columns>
			</OrderItem>
		</>
	);
}

const FieldExtended = styled(Field)`
    max-width: 600px;
    margin: 30px auto 10px auto;
    text-align: center;
`;

const viewMapped = {
	trackingNumber: "trackingNumberById",
	bill: "billById",
	order: "orderById",
};

const isElapsedDaysPolicy = (createdAt, jsonSettings, orderId, pastdueBills) => {
	const { claimsPolicyDays = 60 } = jsonSettings;

	// Facturas que se encuentran en el array de reclamos claimsPastdueBills
	if (pastdueBills) {
		const foundIndex = pastdueBills.findIndex((el) => el === orderId);
		if (foundIndex >= 0) return true;
	}

	// Continuar con el setting de días transcurridos tras la factura (claimsPolicyDays)
	const date1 = new Date(createdAt);
	const date2 = new Date();
	const diff = date2 - date1;

	const days = Math.floor(diff / 86400000);
	return (days <= claimsPolicyDays);
};

function OrderProduct({
	product,
	createdAt,
	item,
	restockItem,
	orderId,
	toggleModal,
	viewItem,
	toggleGalleryModal,
	isChecked,
	_t,
	handleCheckedItems,
	view,
	isOrderAlreadyClaimed,
	isFetchingDone,
	jsonSettings,
	pastdueBills,
}) {
	const [isAddingToCart, setIsAddingToCart] = useState(false);
	const [doneAddingToCart, setDoneAddingToCart] = useState(false);

	let img = item.imagesUrl && item.imagesUrl.length > 0
		? item.imagesUrl[0].thumb
		: "";

	const onHandleRestock = () => {
		setIsAddingToCart(true);
		restockItem(product._id, item.quantity, product).then(() => {
			setIsAddingToCart(false);
			setDoneAddingToCart(true);
		});
	};

	const handleGalleryModal = () => {
		toggleGalleryModal(product.collections);
	};

	const handleViewItem = () => {
		viewItem(item.slug);
	};

	if (!img && product) {
		img = product.imagesUrl && product.imagesUrl.length > 0
			? product.imagesUrl[0].thumb
			: "";
	}

	return (
		<OrderItem>
			<Columns>
				<Column>
					<table>
						<tbody>
							<tr>
								{
									(view === "bill" && isElapsedDaysPolicy(createdAt, jsonSettings, orderId, pastdueBills) && isFetchingDone) ? (
										<td>
											<label>
												<CheckBoxExtended>
													<label>
														<input
															type="checkbox"
															name="acceptedCookies"
															onChange={handleCheckedItems}
															checked={isChecked}
															value={
																item.sku
																	? item.sku
																	: item.slug
															}
															data-quantity={item.quantity}
															data-product-name={product?.title ?? ''}
															data-product-image={
																(product?.imagesUrl?.length > 0) ? 
																	product.imagesUrl[0].thumb : ''
															}
															data-price={item.price ?? ''}
															data-multiple={product?.multiple ?? ""}
														/>
														<span></span>
														<p></p>
													</label>
												</CheckBoxExtended>
											</label>
										</td>
									) : null
								}
								<td className="item-img">
									<figure onClick={handleViewItem}>
										<LazyLoadImage
											src={img}
											alt={item.title}
										/>
									</figure>
								</td>
								<td>
									<Title size="6" onClick={handleViewItem}>
										{item.title ||
											(product
												? product.title ||
													product.title_eng ||
													product.title_pt
												: null)}
										{item.billNumber !== 0 &&
										item.billNumber != null ? (
											<span className="item-tag">
												{_t("billed")}
											</span>
										) : null}
									</Title>

									<ItemDetails>
										<span>
											{_t("price")} ${item.price}
										</span>
										<span>
											{_t("items")} {item.quantity}
										</span>
										<span>Ref: {item.slug}</span>
										<span>
											{_t("total")} $
											{numberWithCommas(
												item.quantity * item.price
											)}
										</span>
										<span>
											{_t("date")} {createdAt}
										</span>
									</ItemDetails>
									<ItemOptions>
										{/* <InternalLink
											to={`/claim/?order=${orderId}&sku=${item.slug}&quantity=${item.quantity}`}
										>
											{_t("addAClaim")}
										</InternalLink> */}
										{product && product.collections ? (
											<a onClick={handleGalleryModal}>
												{_t("seeProductInExibit")}
											</a>
										) : null}
									</ItemOptions>
								</td>
							</tr>
						</tbody>
					</table>
				</Column>
				<Column isNarrow>
					<OrderOptions>
						{product &&
						(product.available > 0 ||
							!product.check_availability) ? (
							<Button
								isFullWidth
								onClick={onHandleRestock}
								loading={isAddingToCart}
							>
								{doneAddingToCart
									? _t("addToCartDone")
									: _t("restock")}
							</Button>
						) : null}
					</OrderOptions>
				</Column>
			</Columns>
		</OrderItem>
	);
};

const MemoizedOrderProduct = React.memo(OrderProduct);

function ActionClaim({
	checkedItems, _t, view, handlePlaceClaim, createdAt, jsonSettings, orderId,
	pastdueBills, isOrderAlreadyClaimed, isFetchingDone,
}) {
	const explain = isElapsedDaysPolicy(createdAt, jsonSettings, orderId, pastdueBills) ? _t("claimExplain") : null;

	if (checkedItems.length > 0) {
		return (
			<ButtonExtended
				small
				primary
				onClick={handlePlaceClaim}
			>
				<span>{_t("addAClaim")}</span>
			</ButtonExtended>
		);
	}

	return (
		<ClaimExplain view={view}>
			{isOrderAlreadyClaimed || !isFetchingDone ? "" : explain}
		</ClaimExplain>
	);
}

function ClaimAlert({
	_t, view, createdAt, isOrderAlreadyClaimed, isFetchingDone, jsonSettings, orderId, pastdueBills,
}) {
	const isClaimView = (view === "bill" && isFetchingDone);

	if (isClaimView && isOrderAlreadyClaimed) {
		return (
			<Message alert>{_t("alreadyClaimed")}</Message>
		);
	}

	if (isClaimView && !isElapsedDaysPolicy(createdAt, jsonSettings, orderId, pastdueBills)) {
		return (
			<Message error>{_t("claimExplainExpired")}</Message>
		);
	}

	return null;
}

function OrdersDetail({
	token,
	orderId,
	order,
	restockItem,
	viewItem,
	user,
	history,
	getBasePath,
	jsonSettings,
}) {
	const [_t, lang] = useTranslation();
	const [products, setProducts] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [modalImageGalleryContent, setModalImageGalleryContent] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingMenu, setIsLoadingMenu] = useState(false);
	const [isOptionsVisible, setIsOptionsVisible] = useState(false);
	const [isIntegrating, setIsIntegrating] = useState(false);
	const [orderFromErp, setOrderFromErp] = useState(null);
	const [ecomerceType, serEcommerceType] = useState("");
	const [ecommerceOther, setEcommerceOther] = useState("");
	const [limit, setLimit] = useState(100);
	const [view, setCurrentView] = useQueryString("view", "QUOTATION");
	const [companyId, setCurrentCompanyId] = useQueryString("companyId", "");
	const [checkedItems, setCheckedItems] = useState([]);
	const [productSearch, setProductSearch] = useState("");
	const [isOrderAlreadyClaimed, setOrderAlreadyClaimed] = useState(false);
	const [isFetchingDone, setFetchingDone] = useState(false);
	const [pastdueBills, setPastdueBills] = useState([]);

	const [handleNotify, Notify] = useNotification({
		timeout: 2000,
	});

	const toggleModal = () => {
		setModalOpen((prev) => !prev);
	};
	const toggleGalleryModal = (content) => {
		setModalImageGalleryContent(content);
	};
	const handleOnChangeEcommerce = (e) => {
		serEcommerceType(e.target.value);
	};

	const toggleOptions = () => {
		setIsOptionsVisible((prev) => !prev);
	};

	const handleCheckedItems = (e) => {
		const { value } = e.target;
		const quantity = e.target.getAttribute("data-quantity");
		const price = e.target.getAttribute("data-price");
		const title = e.target.getAttribute("data-product-name");
		const thumb = e.target.getAttribute("data-product-image");
		const multiple = e.target.getAttribute("data-multiple");
		if (e.target.checked) {
			setCheckedItems((prev) => [...prev, { sku: value, quantity, title, thumb, price, multiple }]);
		} else {
			setCheckedItems((prev) => {
				const superClone = prev.filter((ind) => ind.sku !== value);
				return superClone;
			});
		}
	};

	const handlePlaceClaim = () => {
		localStorage.setItem(
			"items-transfer-claim",
			JSON.stringify(checkedItems)
		);
		history.push(
			`/${getBasePath()}/claim/?companyId=${companyId}&order=${orderId}&hasTransfer=true`
		);
	};

	const handleIntegrationRequest = () => {
		setIsIntegrating(true);
		if (analytics) analytics.track("Integration Request");
		Blueberry.formInsert({
			form_key: "Jsnjnbtvg7vDRid78",
			fields: {
				ecomerceType,
				other: ecommerceOther,
				userId: user._id,
			},
		})
			.then((resp) => {
				setIsIntegrating(false);
				setModalOpen(false);
				handleNotify({
					paragraph: "Integration Requested",
					info: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleProductSearching = (e) => {
		setProductSearch(e.target.value);
	};

	const searchProductSlug = (slug) => {
		const re = new RegExp(productSearch.replace("/", "-sz"), 'i');
		return productSearch === "" || re.test(slug);
	};

	let count = order && order.items ? order.items.length : 0;
	let total = order && order.total ? order.total : 0;
	let createdAt = order && order.createdAt ? getDate(order.createdAt) : 0;
	let expiredDate;

	let items = order && order.items ? order.items : [];
	if (orderFromErp) {
		items = orderFromErp.items;
		count = orderFromErp.items.length;
		total = orderFromErp.total;
		createdAt = getDate(new Date(orderFromErp.updatedAt));
		expiredDate = new Date(createdAt);
		expiredDate.setDate(expiredDate.getDate() + jsonSettings.claimsPolicyDays || 90);
	}

	const productRendered = [];

	for (let i = 0; i < Math.min(productSearch ? count : limit, count); i++) {
		const item = items[i];

		if (searchProductSlug(item.slug)) {
			productRendered.push(
				<MemoizedOrderProduct
					product={products[item.slug]}
					key={item.productId}
					item={item}
					handleCheckedItems={handleCheckedItems}
					isChecked={checkedItems.some(
						(ind) => ind.sku === (item.sku ? item.sku : item.slug)
					)}
					orderId={orderId}
					restockItem={restockItem}
					viewItem={viewItem}
					createdAt={createdAt}
					_t={_t}
					toggleModal={toggleModal}
					toggleGalleryModal={toggleGalleryModal}
					view={view}
					isOrderAlreadyClaimed={isOrderAlreadyClaimed}
					isFetchingDone={isFetchingDone}
					jsonSettings={jsonSettings}
					pastdueBills={pastdueBills}
				/>
			);
		}
	}

	const handleScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 2000
		) {
			setLimit((prev) => prev + 25);
		}
	};

	const generateProforma = () => {
		setIsLoadingMenu(true);
		setIsOptionsVisible(false);
		const windowReference = window.open("", "_blank");

		const callback = (resp) => {
			windowReference.location = `${resp}`;
			// window.open(`${resp}${sort ? "&sort=" + sort : ""}`, "_blank");
			setIsLoadingMenu(false);
			handleNotify({
				paragraph: _t("proformaGenerada"),
				info: true,
			});
		};

		if (view === "QUOTATION") {
			Blueberry.generateProforma({ orderId: token }).then(callback);
		} else {
			Blueberry.generateProformaFromIntegration({
				companyId,
				documentNumber: token,
				typeOfRequest: viewMapped[view],
			}).then(callback);
		}
	};

	const handleImageExport = () => {
		const slugs = items.map((ind) => ind.slug);
		localStorage.setItem("items-transfer", JSON.stringify(slugs));
		history.push(
			`/${getBasePath()}/home-login/image-export/?imageTransfer=true`
		);
	};

	const requestOrderImages = () => {
		handleImageExport();
	};

	const requestOrderData = (e) => {
		setIsLoadingMenu(true);
		setIsOptionsVisible(false);
		const templateModel = e.currentTarget.getAttribute("data-template");

		Blueberry.requestOrderData({ orderId: token, templateModel })
			.then((resp) => {
				setIsLoadingMenu(false);
				handleNotify({
					paragraph: _t("dataRequested"),
					info: true,
				});
			})
			.catch((err) => {
				setIsLoadingMenu(false);
				if (err && err.reason === "no-custom-template-setup") {
					handleNotify({
						paragraph: _t("noCustomTemplateSetup"),
						error: true,
					});
				} else {
					handleNotify({
						paragraph: _t("orderNotFound"),
						error: true,
					});
				}
			});
	};

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	React.useEffect(() => {
		setIsLoading(true);
		if (view === "QUOTATION") {
			Blueberry.OrderById({ id: token }).then(() => {
				setIsLoading(false);
			});
			Blueberry.searchOrderProducts({
				text: "",
				orderId: token,
				includeManualCollection: true,
			}).then((resp) => {
				const newProducts = {};
				resp.map((index) => {
					newProducts[index.slug] = index;
				});
				setProducts(newProducts);
			});
		} else {
			Blueberry.orderFromERP({
				companyId,
				documentNumber: token,
				includeProducts: true,
				typeOfRequest: viewMapped[view],
			})
				.then((resp) => {
					setOrderFromErp(resp.result);
					setIsLoading(false);
					if (resp.products) {
						const newProducts = {};
						resp.products.map((index) => {
							newProducts[index.slug] = index;
						});
						setProducts(newProducts);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			Blueberry.getCompanyPastdueBills()
				.then((resp) => {
					setPastdueBills(resp ? resp : []);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [token]);

	const fetchClaimOrder = async () => {
		const claims = await Blueberry.getClaimsFromUser({claimStatus: "all", orderNumber: orderFromErp?.systemOrderNumber || ""});
		setOrderAlreadyClaimed(claims.length > 0)
		setFetchingDone(true);
	};

	React.useEffect(() => {
		if (orderFromErp) {
			fetchClaimOrder();
		}
	}, [orderFromErp]);

	const isLoadingMaster = isLoading && count === 0;

	return (
		<>
			<Notify />
			<DetalleOrden className="is-active">
				<Header>
					<Container>
						<Level isMobile>
							<LevelLeft>
								<LevelItem>
									<Title size="4" as="h3">
										{_t(view === "bill" ? "invoice" : "order")} N° {orderId}
									</Title>
								</LevelItem>
							</LevelLeft>
							<LevelRight>
								<LevelItem>
									<DropdownExtended
										isActive={isOptionsVisible}
										isRight
									>
										<Button
											onClick={toggleOptions}
											loading={isLoadingMenu}
										>
											<Icons icon="ellipsis-v" />
										</Button>
										<div>
											<div>
												<ul>
													<li>
														<a
															onClick={
																generateProforma
															}
														>
															<Icon>
																<Icons icon="excel" />
															</Icon>
															<span>
																{_t(
																	"generarProforma"
																)}
															</span>
														</a>
													</li>
													{renderIf(
														view === "QUOTATION"
													)(
														<>
															<li>
																<a
																	onClick={
																		requestOrderData
																	}
																	data-template="shopify"
																>
																	<Icon>
																		<Icons icon="shopify" />
																	</Icon>
																	<span>
																		{_t(
																			"requestData"
																		)}{" "}
																		Shopify
																	</span>
																</a>
															</li>
															<li>
																<a
																	onClick={
																		requestOrderData
																	}
																	data-template="magento"
																>
																	<Icon>
																		<Icons icon="magento" />
																	</Icon>
																	<span>
																		{_t(
																			"requestData"
																		)}{" "}
																		Magento
																	</span>
																</a>
															</li>
															<li>
																<a
																	onClick={
																		requestOrderData
																	}
																	data-template="custom"
																>
																	<Icon>
																		<Icons icon="file-export" />
																	</Icon>
																	<span>
																		{_t(
																			"requestData"
																		)}{" "}
																		Custom
																	</span>
																</a>
															</li>
														</>
													)}
													<li>
														<a
															onClick={
																requestOrderImages
															}
														>
															<Icon>
																<Icons icon="images-solid" />
															</Icon>
															<span>
																{_t(
																	"downloadImages"
																)}
															</span>
														</a>
													</li>
												</ul>
											</div>
										</div>
									</DropdownExtended>
								</LevelItem>
							</LevelRight>
						</Level>
						<Subtitle as="h4">
							<span>
								{_t("items")} {count}
							</span>
							<span>
								{_t("total")} $
								{total ? numberWithCommas(total) : 0}
							</span>
							<span>
								{_t("date")} {createdAt}
							</span>
							{
								expiredDate && (
									<span>
										{_t("dueDate")} { getDate(expiredDate) }
									</span>
								)
							}
						</Subtitle>
					</Container>
				</Header>

				<Section>
					<Container>
						<TopSection isMobile>
							<LevelLeft>
								<LevelItem>
									<InternalLink to="/orders">
										<Button small>
											<Icons icon="long-arrow-left" />
											<span>{_t("backToOrders")}</span>
										</Button>
									</InternalLink>
									<ActionClaim 
										checkedItems={checkedItems}
										_t={_t}
										view={view}
										handlePlaceClaim={handlePlaceClaim}
										createdAt={createdAt}
										jsonSettings={jsonSettings}
										orderId={orderId}
										pastdueBills={pastdueBills}
										isOrderAlreadyClaimed={isOrderAlreadyClaimed}
										isFetchingDone={isFetchingDone}
									/>
								</LevelItem>
							</LevelLeft>
						</TopSection>
						<ClaimAlert
							_t={_t}
							view={view}
							createdAt={createdAt}
							isOrderAlreadyClaimed={isOrderAlreadyClaimed}
							isFetchingDone={isFetchingDone}
							jsonSettings={jsonSettings}
							orderId={orderId}
							pastdueBills={pastdueBills}
						/>
						<FieldExtended>
							<Control isExpanded>
								<input type="text" placeholder={_t("searchProductInOrder")} onChange={handleProductSearching} />
							</Control>
						</FieldExtended>
						<Order>
							{isLoadingMaster ? <OrderProductPreloader /> : null}
							{productRendered}
						</Order>
					</Container>
				</Section>
			</DetalleOrden>
			{modalImageGalleryContent ? (
				<FullScreen
					images={modalImageGalleryContent.imagesUrl}
					toggleModal={() => setModalImageGalleryContent(null)}
					modal={modalImageGalleryContent ? true : false}
					currentImage={
						modalImageGalleryContent &&
						modalImageGalleryContent.imagesUrl
							? modalImageGalleryContent.imagesUrl[0].url
							: ""
					}
					set={null}
					alt=""
				/>
			) : null}
			<ModalExtended className={modalOpen ? "is-active" : ""} textCenter>
				<div>
					<Box>
						<Title size="4">{_t("learMoreAboutShops")}</Title>
						<Field>
							<label>{_t("ecommercePlatform")}</label>
							<Radio>
								<label>
									<input
										type="radio"
										name="importedBefore"
										value="shopify"
										onChange={handleOnChangeEcommerce}
									/>
									<span></span>
									<p>Shopify</p>
								</label>
							</Radio>
							<Radio>
								<label>
									<input
										type="radio"
										name="importedBefore"
										value="magento"
										onChange={handleOnChangeEcommerce}
									/>
									<span></span>
									<p>Magento</p>
								</label>
							</Radio>

							<Radio>
								<label>
									<input
										type="radio"
										name="importedBefore"
										value="bigcommerce"
										onChange={handleOnChangeEcommerce}
									/>
									<span></span>
									<p>BigCommerce</p>
								</label>
							</Radio>
							<Radio>
								<label>
									<input
										type="radio"
										name="importedBefore"
										value="woocommerce"
										onChange={handleOnChangeEcommerce}
									/>
									<span></span>
									<p>WooCommerce</p>
								</label>
							</Radio>
							<Radio>
								<label>
									<input
										type="radio"
										name="importedBefore"
										value="other"
										onChange={handleOnChangeEcommerce}
									/>
									<span></span>
									<p>Otro</p>
								</label>
							</Radio>
						</Field>
						{ecomerceType === "other" ? (
							<Field>
								<Control>
									<label>
										{_t("whatIsYourEcommercePlatform")}
									</label>
									<input
										type="text"
										value={ecommerceOther}
										onChange={(e) =>
											setEcommerceOther(e.target.value)
										}
									/>
								</Control>
							</Field>
						) : null}
						<Button
							primary
							loading={isIntegrating}
							onClick={handleIntegrationRequest}
						>
							Integrar
						</Button>
					</Box>
				</div>
				<button aria-label="close" onClick={toggleModal}></button>
				<span aria-label="close" onClick={toggleModal}></span>
			</ModalExtended>
		</>
	);
}

export default OrdersDetail;
