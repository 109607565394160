import React from "react";
import { Helmet } from "react-helmet";
import withTracker from "../../blueberry/connectPromise";
import ProductsSectionTemplate from "../templates/ProductsSectionTemplate";
import BlockSection from "./cmpt/Block";
import { Container, Section, Title, Heading } from "../sdk/Layout";
import { getUrls } from "../utils";

class Page extends ProductsSectionTemplate {
    state = {
        faqs: [],
        featured: [],
        isLoadingFags: true,
        isLoadingFeatured: true,
        isSearching: false,
    };

    renderBody() {
        const { lang } = this.props.match.params;
        const { menusData, jsonSettings } = this.props;
        const { user } = this.props.store;
        // const langJson = getLang(lang);

        const { pathname } = this.props.location;

        const { enUrl, esUrl, ptUrl } = getUrls(pathname, lang);

        let config = menusData && menusData[0] ? menusData[0].menu : [];
        if (this.isLoggedIn())
            config = menusData && menusData[1] ? menusData[1].menu : [];

        return (
            <>
                <Helmet>
                    <title>{this._t("allDepartments")}</title>
                </Helmet>
                <Section>
                    <Container>
                        <Heading isCentered>
                            <h3>{this._t("allDepartments")}</h3>
                        </Heading>

                        {config.map((index, i) => (
                            <BlockSection
                                key={`block-${i}`}
                                menu={index}
                                jsonSettings={jsonSettings}
                                user={user}
                            />
                        ))}
                    </Container>
                </Section>
            </>
        );
    }
}

export default withTracker()(Page);
