import React from "react";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	Slider,
	Section,
	Featured,
	FeaturedImage,
	Btn,
	Heading,
	Subtitle,
	Paragraph,
	Spacer,
	CategoryList,
	Locations,
} from "./style";
import { Buttons } from "../../sdk/Button";
import Icon from "./components/Icons";
import { Container } from "../../sdk/Layout";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import InternalLink from "../../components/InternalLink";
import Layout from "./components/Layout";

function getLang(lang) {
	switch (lang) {
		case "es":
			return lang_es;
		case "en":
			return lang_en;
		case "pt":
			return lang_pt;
	}
}

const configLeadForm = [{ formKey: "WfhAreyqx692vCgoi" }];

class MarcaPrivadaHome extends ProductsSectionTemplate {
	state = {};

	renderHeader = () => null;
	// renderFooter = () => null;

	renderBody() {
		const { lang } = this.props.match.params;

		const {
			seo,
			slider,
			featured,
			featured_btn,
			section1,
			cat_heading,
			categories,
			locations,
			section2,
			section3,
			conoce_mas,
		} = getLang(lang).home;

		return (
			<Layout lang={lang} className="home">
				<Slider>
					<div className="solid"></div>
					<div className="bg">
						<LazyLoadImage
							src="https://www.noritex.com/uplds/2023/10/27/jqefePRbkQ4i8ui2KBanner-Home--(800x800).jpg"
							alt="Noritex"
						/>
						<div className="overlay"></div>
					</div>
					<div className="inner">
						<Container>
							<div className="text">
								<h1>{slider.title}</h1>
								<h2>{slider.subtitle}</h2>
								<InternalLink to="/marca-privada/registrarse">
									<Btn primary className="large">
										{slider.btn}
									</Btn>
								</InternalLink>
							</div>
						</Container>
					</div>
				</Slider>

				<Spacer desktop="100" mobile="20" />
				<Subtitle dangerouslySetInnerHTML={{ __html: section3 }} />

				<Section>
					<Container>
						<Featured>
							{featured.slice(0, 3).map((item, index) => (
								<div key={index}>
									<span>
										<Icon icon={item.icon} />
									</span>
									<h2>{item.title}</h2>
									<h3>{item.description}</h3>
								</div>
							))}
						</Featured>
						<Spacer desktop="30" />
						<Buttons isCentered>
							<InternalLink to="/marca-privada/beneficios">
								<Btn primary className="borderless raised">
									{featured_btn}
								</Btn>
							</InternalLink>
						</Buttons>
					</Container>
				</Section>

				<FeaturedImage>
					<Container>
						<LazyLoadImage
							src="https://www.noritex.com/uplds/2023/10/27/37nmYfcojTPr7wki4Banner-Eleva-tu-marca-(1300x750).jpg"
							alt="Noritex"
						/>
					</Container>
				</FeaturedImage>

				<Section>
					<Heading>
						<h2
							dangerouslySetInnerHTML={{
								__html: section1.title,
							}}></h2>
					</Heading>
					<Spacer desktop="100" mobile="50" />
					<Paragraph className="justify">
						<p>{section1.paragraph}</p>
					</Paragraph>
					<Spacer desktop="100" mobile="50" />
					<Container>
						<Heading>
							<h2
								dangerouslySetInnerHTML={{
									__html: cat_heading,
								}}></h2>
						</Heading>
						<Spacer desktop="100" mobile="50" />
						<CategoryList>
							{categories.map((item, index) => (
								<div className="item" key={index}>
									<div className="image">
										<LazyLoadImage
											src={`${item.thumb}`}
											alt="Noritex"
										/>
									</div>
									<div className="content">
										<div className="inner">
											<h3>{item.title}</h3>
											<p>{item.description}</p>
											<InternalLink
												to={`/marca-privada/categorias/${item.slug}`}>
												<Btn className="borderless raised">
													{conoce_mas}
												</Btn>
											</InternalLink>
										</div>
									</div>
								</div>
							))}
						</CategoryList>
					</Container>
				</Section>

				<Locations>
					<Container>
						<div className="inner">
							<div>
								<Heading white align="right">
									<h2
										dangerouslySetInnerHTML={{
											__html: locations.title,
										}}></h2>
								</Heading>
							</div>
							<div>
								<ul>
									{locations.countries.map((item, index) => (
										<li key={index}>{item}</li>
									))}
								</ul>
							</div>
						</div>
					</Container>
				</Locations>

				<FeaturedImage>
					<Spacer desktop="100" tablet="0" />
					<Container>
						<LazyLoadImage
							src="https://www.noritex.com/uplds/2023/10/27/x78KxySFGJL5fZfqTBanner-Desarrolla-tu-marca--(1300x750).jpg"
							alt="Noritex"
						/>
					</Container>
				</FeaturedImage>

				<Section>
					<Heading>
						<h2
							dangerouslySetInnerHTML={{
								__html: section2.title,
							}}></h2>
					</Heading>
					<Spacer desktop="100" mobile="50" />
					<Paragraph className="justify">
						<p>{section2.paragraph}</p>
					</Paragraph>
					<Spacer desktop="100" mobile="50" />
					<Buttons isCentered>
						<InternalLink to="/marca-privada/registrarse">
							<Btn className="large" primary>
								{section2.btn}
							</Btn>
						</InternalLink>
					</Buttons>
				</Section>

				<SEOFieldsHelmet
					seo={seo}
					lang={lang}
					defaultTitle={seo.title}
					defaultDescription={seo.description}
					bodyClassName="reset-body back-top-btn-hidden"
				/>

				<ConsentManager lang={lang} bypasConsent={true} />
			</Layout>
		);
	}
}

export default withTracker()(MarcaPrivadaHome);
